import { useSelector } from 'react-redux';

import { useUser } from 'context/UserContext';
import { getSelectedOrganization } from 'selectors/organizations';

type TrackerBasicInfo = {
  user_id: string;
  organization_id: string;
  country: string;
};

const useTrackerBasicInfo = () => {
  const user = useUser();
  const organization = useSelector(getSelectedOrganization);

  const defaultParams: TrackerBasicInfo = {
    user_id: user?.id ?? '',
    organization_id: organization?.id ?? '',
    country: organization?.country_code ?? '',
  };

  return defaultParams;
};

export default useTrackerBasicInfo;

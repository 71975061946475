interface Configuration {
  REACT_APP_SITE_ENV: string;
  REACT_APP_SENTRY_DSN: string;
  REACT_APP_QLINK_URL: string;
  REACT_APP_QLEARN_URL: string;
  REACT_APP_QUIPPER_URL: string;
  REACT_APP_UPLOAD_QUESTIONS_TEMPLATE_URL: string;
  REACT_APP_UPLOAD_QUESTIONS_GPT: string;
  REACT_APP_MEIKO_QCREATE_URL: string;
  REACT_APP_VERSION: string;
  REACT_APP_BUILD_VERSION: string;
  REACT_APP_FIREBASE_API_KEY: string;
  REACT_APP_FIREBASE_AUTH_DOMAIN: string;
  REACT_APP_FIREBASE_DATABASE_URL: string;
  REACT_APP_FIREBASE_PROJECT_ID: string;
  REACT_APP_FIREBASE_STORAGE_BUCKET: string;
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: string;
  REACT_APP_FIREBASE_APP_ID: string;
  REACT_APP_FIREBASE_MEASUREMENT_ID: string;
}

const config: Configuration = {
  ...{
    REACT_APP_SITE_ENV: '',
    REACT_APP_SENTRY_DSN: '',
    REACT_APP_QLINK_URL: '',
    REACT_APP_QLEARN_URL: '',
    REACT_APP_QUIPPER_URL: '',
    REACT_APP_UPLOAD_QUESTIONS_TEMPLATE_URL: '',
    REACT_APP_UPLOAD_QUESTIONS_GPT: '',
    REACT_APP_MEIKO_QCREATE_URL: '',
    REACT_APP_VERSION: '',
    REACT_APP_BUILD_VERSION: '',
    REACT_APP_FIREBASE_API_KEY: '',
    REACT_APP_FIREBASE_AUTH_DOMAIN: '',
    REACT_APP_FIREBASE_DATABASE_URL: '',
    REACT_APP_FIREBASE_PROJECT_ID: '',
    REACT_APP_FIREBASE_STORAGE_BUCKET: '',
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID: '',
    REACT_APP_FIREBASE_APP_ID: '',
    REACT_APP_FIREBASE_MEASUREMENT_ID: '',
  },
  ...process.env,
};

export default config;

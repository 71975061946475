import { useSelector } from 'react-redux';
import { useLocation } from '@reach/router';

import { getQuestion } from 'selectors/questions';
import { getChapter } from 'selectors/chapters';
import { getTopic } from 'selectors/topics';
import { MathEventParams } from 'utils/firebase/mathEquationTracker';
import useTrackerBasicInfo from 'hooks/useTrackerBasicInfo';

const useMathEventParams = (): MathEventParams => {
  const { pathname } = useLocation();
  const trackerBasicInfo = useTrackerBasicInfo();

  const entityId = pathname.split('/')[3] ?? '';
  const question = useSelector(getQuestion(entityId));
  const chapter = useSelector(getChapter(entityId));
  const topicQuestion = useSelector(getTopic(question?.topic_id ?? ''));
  const topicChapter = useSelector(getTopic(chapter?.topic_id ?? ''));
  const topic = topicQuestion || topicChapter;

  if (pathname.includes('import')) {
    return {
      ...trackerBasicInfo,
      topic_id: pathname.split('/')[3] ?? '',
      position: 'upload_question',
      position_id: null,
      position_number: null,
    };
  }

  let positionNumber = question?.position ? question.position + 1 : 0;
  if (!positionNumber) {
    if (question && topic?.questions) {
      positionNumber =
        topic.questions.findIndex((q: { id: string }) => q.id === entityId) + 1;
    }

    if (chapter && topic?.lesson?.chapters) {
      positionNumber =
        topic.lesson.chapters.findIndex(
          (c: { id: string }) => c.id === entityId,
        ) + 1;
    }
  }

  return {
    ...trackerBasicInfo,
    topic_id: question?.topic_id ?? chapter?.topic_id ?? '',
    position: question ? 'question' : 'chapter',
    position_id: question?.id ?? chapter?.id ?? '',
    position_number: positionNumber,
  };
};

export default useMathEventParams;
